import React, { useEffect } from 'react';
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Paper, Tab, Tabs } from '@material-ui/core';
import urljoin from 'url-join';

import Tooltip from '~/components/core/Atomic/Tooltip';

import { updateOrganizationLocaleDetails } from '../../../CmsMain/globals';
import { useCms } from '../../../hooks/useCms';
import LoadingIndicator from '../../../LoadingIndicator';
import useOrganization from '../../../OrganizationContext';
import useDataFetcher from '../../../useDataFetcher';
import { adminCustomerFacingTabs as tabs } from '../routes';
import SysconfigContext from '../SystemContext';

import { useStyles } from '../../../../assets/styles';

const truncateLabel = (label) => {
  const maxLength = 20;
  if (label.length > maxLength) {
    return (
      <Tooltip title={label}>
        <span>{label.substring(0, maxLength - 3) + '...'}</span>
      </Tooltip>
    );
  }
  return label;
};

const ExternalSystemConfigurationScreen = ({ organizationId }) => {
  const classes = useStyles();
  const { user, setPageTitle } = useCms();
  const match = useRouteMatch();
  const location = useLocation();
  const organizationContext = useOrganization();

  const orgId = organizationId || user.organization_id;

  const {
    isLoading: isLoadingOrganization,
    isError: isErrorOrganization,
    data: organization,
    reloadData: reloadOrganization,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${orgId}`);
  const {
    isLoading: isUsersLoading,
    isError: isUsersError,
    data: allUsers,
    reloadData: reloadUsers,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${orgId}/users`, { params: { include_deactivated: true } });
  const users = React.useMemo(() => allUsers?.filter((user) => !user.is_removed), [allUsers]);
  const {
    isLoading: isRolesLoading,
    isError: isRolesError,
    data: roles,
    reloadData: reloadRoles,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${orgId}/roles`);
  const isLoading = isUsersLoading || isRolesLoading || isLoadingOrganization || organizationContext.isLoading;
  const isError = isUsersError || isRolesError || isErrorOrganization || organizationContext.isError;

  const path = location.pathname.substring(match.url.length + 1);
  const breadcrumbs = path.split('/');
  let currTabValue = tabs.findIndex((tab) => breadcrumbs.includes(tab.url));
  currTabValue = currTabValue >= 0 ? currTabValue : 0;
  const TabLabel = tabs[currTabValue].label;

  useEffect(() => {
    const pageTitle = [
      'System Configuration',
      organization ? organization.name : null,
      organization?.organization_type === 'demo' ? 'Demo' : null,
    ]
      .filter(Boolean)
      .join(' - ');

    const documentTitle = `System Configuration - ${TabLabel} — Five Sigma CMS`;

    setPageTitle(pageTitle, documentTitle);
  }, [setPageTitle, TabLabel, organization]);

  useEffect(() => {
    if (isLoading || isError) {
      return;
    }

    // For new/editing template we should know the region to know if display the state field or not
    updateOrganizationLocaleDetails(organization);
  }, [isLoading, isError, organization]);

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  return (
    <>
      <div>
        <Paper className="bg-slate-200">
          <Tabs value={currTabValue} style={{ minHeight: 40 }} variant="scrollable">
            {tabs.map((tab) => (
              <Tab
                style={{
                  minHeight: 40,
                  padding: 0,
                }}
                key={tab.url}
                label={truncateLabel(tab.label)}
                component={Link}
                to={`${match.url}/${tab.url}`}
              />
            ))}
            ;
          </Tabs>
        </Paper>
      </div>
      <div className={classes.pageBody}>
        <Switch>
          <Redirect exact from={`${match.path}/`} to={urljoin(match.path, tabs[0].url)} />
          {tabs.map((tab) => (
            <Route
              key={tab.url}
              path={urljoin(match.path, tab.url)}
              render={() => {
                return (
                  <SysconfigContext.Provider
                    value={{
                      isLoading,
                      isError,
                      organization,
                      reloadOrganization,
                      allUsers,
                      users,
                      reloadUsers,
                      roles,
                      reloadRoles,
                      organizationOperationalDetails: organizationContext,
                      reloadOperationalDetails: organizationContext.reloadOperationalDetails,
                    }}
                  >
                    <tab.component />
                  </SysconfigContext.Provider>
                );
              }}
            />
          ))}
          ;
          <Redirect to={match.path} />
        </Switch>
      </div>
    </>
  );
};

ExternalSystemConfigurationScreen.propTypes = {
  organizationId: PropTypes.number,
};

export default ExternalSystemConfigurationScreen;
